import React from "react";
import { FormattedMessage, Link } from "gatsby-plugin-intl";

import Layout from "../components/layout";
import ApplicationProcessDiagram from "../components/application-process-diagram";

const ConfirmedPage = () => (
  <Layout>
    <h1 className="block-title">
      {" "}
      <FormattedMessage
        id="confirm_proposal_submit"
        defaultMessage="Thanks for submitting your proposal"
      />
    </h1>
    <h2 className="anchor-title">
      {" "}
      <FormattedMessage
        id="confirm_proposal_submit_subheader"
        defaultMessage="What happens next?"
      />
    </h2>
    <Link to="/my-proposals" className="btn btn-primary">
      <FormattedMessage
        id="view_my_proposals"
        defaultMessage="View My Proposals"
      />
      <img
        className="case-study-item__link-icon ms-1"
        src="/img/chevron-right-white.svg"
        alt=""
      />
    </Link>
    <h3>
      <FormattedMessage
        id="project_lifecycle_title"
        defaultMessage="The Lifecyle of a Performance Project"
      />
    </h3>
    <ApplicationProcessDiagram
      expanded={false}
      completedSteps={[1]}
    ></ApplicationProcessDiagram>
  </Layout>
);

export default ConfirmedPage;
