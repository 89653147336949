import React from "react";
import { FormattedMessage, Link } from "gatsby-plugin-intl";

interface DiagramOptions {
  expanded: boolean;
  completedSteps?: number[];
}

const ApplicationProcessDiagram = ({
  expanded,
  completedSteps,
}: DiagramOptions) => (
  <div className="diagram-container">
    <ol
      className={`circle-container ${
        expanded ? "circle-container--expanded" : ""
      }`}
    >
      {/* Step 1 */}
      <li
        className={`circle ${
          !!completedSteps && completedSteps.includes(1) ? "completed" : ""
        }`}
      >
        <div className="circle-inner">
          {" "}
          <h1 className="circle-inner__header">
            <FormattedMessage
              id="process_diagram.submission"
              defaultMessage="Submission"
            />
          </h1>
          {!!expanded && (
            <div className="card circle-extra card circle-extra-1">
              <p>
                <FormattedMessage
                  id="process_diagram.submission_text"
                  defaultMessage="Tell us about a challenge that you are experiencing in your
                sport, or an opportunity you would like to exploit."
                />
              </p>
            </div>
          )}
        </div>
      </li>
      {/* Step 2 */}
      <li className="circle">
        <div className="circle-inner">
          <h1 className="circle-inner__header">
            <FormattedMessage
              id="process_diagram.evaluation"
              defaultMessage="Evaluation"
            />
          </h1>
          {!!expanded && (
            <div className="card circle-extra card circle-extra-2">
              <p>
                <FormattedMessage
                  id="process_diagram.evaluation_text"
                  defaultMessage="We will evaluate your submission using the {prioritisationFrameworkLink} and the {principlesOfIADLInk}. Our performance advisors and practitioner teams may need to follow up with you once your submission has been received to clarify and/or develop some areas"
                  values={{
                    prioritisationFrameworkLink: (
                      <Link to={"/prioritisation-framework"}>
                        {" "}
                        <FormattedMessage
                          id="prioritisation_framework"
                          defaultMessage="Prioritisation Framework"
                        />
                      </Link>
                    ),
                    principlesOfIADLInk: (
                      <Link
                        to={
                          "/resources/inclusive-athlete-development-principles"
                        }
                      >
                        {" "}
                        <FormattedMessage
                          id="inclusive_athlete_development_principles"
                          defaultMessage="Principles of Inclusive Athlete Development"
                        />
                      </Link>
                    ),
                  }}
                />
              </p>
            </div>
          )}
        </div>
      </li>
      {/* Step 3 */}
      <li className="circle">
        <div className="circle-inner">
          <h1 className="circle-inner__header">
            <FormattedMessage
              id="process_diagram.confirmation_of_delivery_team"
              defaultMessage="Confirmation of Delivery Team"
            />
          </h1>
          {!!expanded && (
            <div className="card circle-extra card circle-extra-3">
              <p>
                <FormattedMessage
                  id="process_diagram.planning_text"
                  defaultMessage="Once your project is accepted, a sport science and medicine delivery team will be appointed so that practitioner expertise is matched with the agreed project outcomes."
                />
              </p>
            </div>
          )}
        </div>
      </li>
      {/* Step 4 */}
      <li className="circle">
        {" "}
        <div className="circle-inner">
          <h1 className="circle-inner__header">
            <FormattedMessage
              id="process_diagram.planning"
              defaultMessage="Planning"
            />
          </h1>
          {!!expanded && (
            <div className="card circle-extra card circle-extra-4">
              <p>
                <FormattedMessage
                  id="process_diagram.delivery_text"
                  defaultMessage="Following project acceptance and delivery team confirmation, we will work with you to co-create a support plan that will maximise the chances of a successful project outcome."
                />
              </p>
            </div>
          )}
        </div>
      </li>
      {/* Step 5 */}
      <li className="circle">
        <div className="circle-inner">
          <h1 className="circle-inner__header">
            <FormattedMessage id="delivery" defaultMessage="Delivery" />
          </h1>
          {!!expanded && (
            <div className="card circle-extra card circle-extra-5">
              <p>
                <FormattedMessage
                  id="process_diagram.review_text"
                  defaultMessage="We will work together to successfully delivery on the collaboratively agreed project plan and goals.
                  "
                />
              </p>
            </div>
          )}
        </div>
      </li>
      {/* Step 6 */}
      <li className="circle">
        <div className="circle-inner">
          <h1 className="circle-inner__header">
            <FormattedMessage
              id="process_diagram.review_and_learn"
              defaultMessage="Review & Learn"
            />
          </h1>
          {!!expanded && (
            <div className="card circle-extra card circle-extra-6">
              <p>
                <FormattedMessage
                  id="process_diagram.learn_text"
                  defaultMessage="This will loop through the entire project life cycle as our ability to review and learn from everything we do is key to ensuring the project goals remain on track. This will enable us to remain agile in our delivery approach."
                />
              </p>
            </div>
          )}
        </div>
      </li>
    </ol>
  </div>
);

export default ApplicationProcessDiagram;
